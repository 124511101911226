import { Dispatch } from "redux";
import { CLEAR_USER_DETAILS, COD, SET_USER_DETAILS } from "../types";
import { uniqueCode } from "./authAction";

export const setUserDetails: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload,
    });
  };

export const clearUserDetails: any = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_USER_DETAILS,
  });
};

export const setUniqueCode: any = (payload:any) => async (dispatch: Dispatch) => {
  dispatch({
    type: COD,
    payload:{
      uniqueCode:payload
    }
  });
};