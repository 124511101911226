import { CLEAR_USER_DETAILS, SET_USER_DETAILS, COD} from "../types";

export interface UserReducerStore {
  name: string;
  email: string;
  mobile: string;
  uniqueCode: string;
}

const initialState: UserReducerStore = {
  name: "",
  mobile: "",
  email: "",
  uniqueCode: "",
};

type ActionType =
   {
      type: "SET_USER_DETAILS";
      payload: {
        name: string;
        email: string;
        mobile: string;
        uniqueCode: string;
      };
    }
  | {
      type: "CLEAR_USER_DETAILS";
    } |

    {
      type: "COD";
      payload:{
        uniqueCode: string
      }
    }
    
    ;

export default function userReducer(state = initialState, action: ActionType):UserReducerStore {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER_DETAILS:
      return {
        ...initialState
      };
      case COD:{
        return {
          ...state,
          uniqueCode: action.payload.uniqueCode,
        }
      }
    default:
      return state;
  }
}
