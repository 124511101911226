import MainLayout from "../../layouts/MainLayout";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, OUTLET, ROUTES, STATES } from "../../lib/consts";
import store from "../../store/store";
import { canClaim, setAccessToken, limit, } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import { useAuthentication } from "../../hooks/useAuthentication";
import { setUserIdentification } from "../../store/actions/authAction";
import { log } from "console";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import { useSearchParams } from "react-router-dom";
import headerlogo from "../../assets/images/header-logo.png";
import candy from "../../assets/images/Candy-Unit.png"



const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [searchParams] = useSearchParams();
  const referralCodeFromUrl = searchParams.get("referral");
  const [tokenValue, setTokenValue] = useState("");
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const [err, setErr] = useState("");

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        navigate(ROUTES.PAYMENT_PAGE); // Change this to the appropriate route
      };

      window.addEventListener("popstate", handleLocationChange);

      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, isLoggedIn]);

  const RegisterValidation = Yup.object().shape({
    code: Yup.string().required(
      "Please enter valid Unique Code"
    ), name: Yup.string()
      .required("Enter valid name")
      .matches(/^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/, "Enter valid name"),
    mobile: Yup.string()
      .required("*Please enter valid 10-digit number")
      .matches(
        /^[56789][0-9]{9}$/,

        "*Please enter valid 10-digit number"
      ),


    agree: Yup.boolean().oneOf(
      [true],
      " Please agree to terms and conditions"
    ),

    state: Yup.string().required(
      "*Please select a State"
    ),

  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
        "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // const navigate = useNavigate();


  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          mobile: "",
          name: "",
          code: "",
          state: "",
          agree: false,
          tokenValue
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          console.log("values", values);
          // setShowOtpForm(true);
          if (values.agree) {
            console.log(values);
            startTimer();
            // values.tokenValue = tokenValue
            API.register(values)
              .then(() => {
                // gtagTrackEvent(GA_EVENTS.Registered);
                setShowOtpForm(true);
                startTimer();
              })
              .catch((err) => {
                const { messageId } = err;
                switch (messageId) {
                  case ERROR_IDS.INVALID_MOBILE:
                    errors.setErrors({
                      mobile: "Invalid Mobile Number",
                    });
                    break;

                  default:
                    errors.setErrors({
                      mobile: err.message,
                    });
                    break;
                }
              });
          } else {
            errors.setErrors({
              agree: "*Please agree to the consent",
            });
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <div className="register-container">
              {
                <img
                  src={headerlogo}
                  alt="logo"
                  className="logo"
                // onClick={() => navigate(ROUTES.REGISTER)}
                />
              }
              <div className="content">

                <Form onSubmit={handleSubmit} >
                  <div className="register-form" >

                    <div className="input-group">
                      <input
                        autoComplete="off"
                        type="text"
                        onChange={handleChange}
                        value={values.code}
                        name="code"
                        maxLength={10}
                        onBlur={handleBlur}
                        placeholder="Enter the Unique Code"
                      />
                    </div>

                    <div className="input-group">
                      <label className="input-label"></label>
                      <input
                        autoComplete="off"
                        type="text"
                        onChange={handleChange}
                        value={values.name}
                        maxLength={35}
                        name="name"
                        onBlur={handleBlur}
                        placeholder="Name"
                        pattern="[A-Za-z\s]*"
                      />
                    </div>
                    <div className="input-group">
                      <label className="input-label"></label>
                      <input
                        autoComplete="off"
                        type="tel"
                        onChange={handleChange}
                        value={values.mobile}
                        name="mobile"
                        maxLength={10}
                        onBlur={handleBlur}
                        placeholder="Mobile Number"
                      />
                    </div>


                    <div className="input-group arrow-after">
                      <select
                        name="state"
                        value={values.state}
                        onChange={(e) => {
                          handleChange(e);
                          // const selectedState = e.target.value;

                        }}
                        onBlur={handleBlur}
                      >
                        <option value="" label="Select State" />
                        {STATES.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input-group flex-center">
                      <label className="checkbox-container">
                        <span>
                          {" "}
                          I Accept the <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                            <u className="terms-link">Terms & Conditions{""}</u>
                          </span>
                        </span>
                        <input
                          type="checkbox"
                          id="agree"
                          name="agree"
                          onChange={(e) => {
                            setFieldValue("agree", e.target.checked);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    {errors.code && touched.code && (
                      <p className="error">{errors.code}</p>
                    )}
                    {
                      !errors.code &&
                      errors.name &&
                      touched.name && <p className="error">{errors.name}</p>}

                    {
                      !errors.code &&
                      !errors.name &&
                      errors.mobile &&
                      touched.mobile && <p className="error">{errors.mobile}</p>}

                    {
                      !errors.code &&
                      !errors.name &&
                      !errors.mobile &&
                      errors.state &&
                      touched.state && <p className="error">{errors.state}</p>}


                    {/* <div style={{ margin: "1rem auto" }} id="cf-turnstile-otp"></div> */}
                    {
                      !errors.code &&
                      !errors.name &&
                      !errors.mobile &&
                      !errors.state &&
                      errors.agree &&
                      touched.agree && (
                        <p className="error">{errors.agree}</p>
                      )}

                    <p className="error">{err}</p>
                    <button className="btn btn-primary" type="submit">
                      SUBMIT
                    </button>
                  </div>

                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (

      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.DETAILS);
          API.verifyOTP(values.otp)
            .then((response) => {
              // console.log("Access Token:", response.accessToken);
              store.dispatch(setAccessToken(response.accessToken));
              store.dispatch(limit(response.limit));
              // store.dispatch(limit(response.amt));
              // localStorage.setItem("amt", response.amt.toString());
              // store.dispatch(amt(response.amt))
              // gtagTrackEvent(GA_EVENTS.Verify_OTP);
              const questionData = response.data;

              const avatar = response.avatar;
              if (avatar) {
                switch (avatar.toUpperCase()) {
                  case "explorer-girl":
                    navigate(ROUTES.EXPLORERGIRL);
                    break;
                  case "fashionista":
                    navigate(ROUTES.DETAILS);
                    break;
                  case "gamer":
                    navigate(ROUTES.GAMER);
                    break;
                  case "music-buff":
                    navigate(ROUTES.MUSIC);
                    break;
                    case "sports-lover":
                    navigate(ROUTES.SPORTS);
                    break;
                    case "explorer-boy":
                    navigate(ROUTES.EXPLORER);
                    break;
                  default:
                    navigate(ROUTES.EXPLORER);
                    break;
                }
              } else {
                navigate(ROUTES.HOME); // Fallback in case avatar is missing
              }
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp: "Please enter a valid OTP",
                  });
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <div className="otp-container">
              <Form onSubmit={handleSubmit}>
                <img className="candy-image" src={candy} />
                <div className="otp-form" style={{ width: "90%", margin: "0 auto" }}>
                  <div className="input-group">
                    <p className="form-title" style={{ textAlign: "left", paddingBottom: "1rem" }}>ENTER OTP</p>
                    <input
                      autoComplete="off"
                      type="tel"
                      onChange={handleChange}
                      value={values.otp}
                      name="otp"
                      maxLength={6}
                      onBlur={handleBlur}
                      placeholder="Enter One Time passcode"
                    />
                  </div>
                  {errors.otp && touched.otp && (
                    <p className="error">{errors.otp}</p>
                  )}
                  <div className="flex-center timer-wrapper">
                    {resendTimer > 0 && (
                      <div className="timer flex-center">{resendTimer}</div>
                    )}
                    <span>{t("registerPage.didNotReceive")}</span>
                    <span className="link" onClick={resendOtp}>
                      {t("registerPage.resend")}
                    </span>
                  </div>
                </div>
                <button className="btn btn-primary" type="submit" >
                  REGISTER
                </button>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
