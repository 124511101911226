import React from "react";
import "./NotAllowed.scss";
import MainLayout from "../../layouts/MainLayout";
import gamercenter from "../../assets/images/gamer-center.png"
import reliancelogo from "../../assets/images/reliance.png"
import starImage1 from "../../assets/images/top-left.png"
import starImage2 from "../../assets/images/top-right.png"


export const Gamer = () => {
  return (
    <MainLayout className="Gamer-page">
      <div className="Gamer-container">
        <div className="congratulations-card">
        

          
          <p className="stay-tuned">Please scan the QR Code<br/> to Participate</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default Gamer;
