import React from "react";
import "./Explorergirl.scss";
import MainLayout from "../../layouts/MainLayout";
import girlcenter from "../../assets/images/girl-center.png"
import makemytrip from "../../assets/images/makemytrip.png"
import starImage1 from "../../assets/images/top-left.png"
import starImage2 from "../../assets/images/top-right.png"


export const Explorergirl = () => {
  return (
    <MainLayout className="Explorergirl-page">
      <div className="Explorergirl-container">
        <div className="congratulations-card">
        <div className="congrats-wrapper">
            <img
              className="small-image top-left"
              src={starImage1}
              alt="Top Left Decoration"
            />
            <p className="congrats-text">Congratulations!</p>
            <img
              className="small-image top-right"
              src={starImage2}
              alt="Top Right Decoration"
            />
          </div>
          <p className="unlock-text1">You have unlocked</p>
          <p className="unlock-text2">Explorer Girl</p>

          <div className="avatar-card">
            <img
              src={girlcenter}
              alt="Fashionista Avatar"
            />
          </div>

          <p className="voucher-info">
            You will receive your
            <img
              className="brand-logo"
              src={makemytrip}
              alt="Myntra Logo"
            />
            voucher, if you are<br/>one of the lucky winners!
          </p>
          <p className="stay-tuned">STAY TUNED...</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default Explorergirl;
