import { useMemo } from "react";
import Logo from "../../assets/images/header-logo.png";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import "./SideMenu.scss";
import { useNavigate } from "react-router-dom";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import { useTranslation } from "react-i18next";

const SideMenu = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const { showModal } = useGlobalModalContext();
  const { t, i18n } = useTranslation();

  const menuOptions = useMemo(
    () => [
      {
        name: t("sideMenu.howToRedeem"),
        route: ROUTES.REGISTER,
        onClick: () => {
          showModal(MODAL_TYPES.HOWTOREDEEM);
          onClose();
        },
      },
      {
        name: t("sideMenu.tandc"),
        route: ROUTES.REGISTER,
        onClick: () => {
          showModal(MODAL_TYPES.TERMS);
          onClose();
        },
      },
      {
        name: t("sideMenu.contactUs"),
        route: ROUTES.REGISTER,
        onClick: () => {
          showModal(MODAL_TYPES.CONTACT_US);
          onClose();
        },
      },
      // {
      //   name: "Withdraw Consent",
      //   route: ROUTES.REGISTER,
      //   onClick: () => {
      //     showModal(MODAL_TYPES.CONSENT);
      //     onClose();
      //   },
      // },
    ],
    [t]
  );
  return (
    <div className={`side-menu ${open ? "opened" : "closed"}`}>
      <div className="bg" onClick={onClose}></div>
      <div className="modal">
        <img
          src={Logo}
          alt="logo"
          // onClick={() => navigate(ROUTES.REGISTER)}
          className="logo"
        />
        <div className="options">
          {menuOptions.map((item) => (
            <div
              className="option"
              onClick={() => item.onClick && item.onClick()}
              key={item.name}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
