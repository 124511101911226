import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="terms-popup" title="Terms & Conditions">
      <p className="terms-sub-title">
        coming-soon
      </p>
      

    </Popup>
  );
};

export default TermsPopup;
