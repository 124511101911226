import React from "react";
import "./Gamer.scss";
import MainLayout from "../../layouts/MainLayout";
import gamercenter from "../../assets/images/gamer-center.png"
import reliancelogo from "../../assets/images/reliance.png"
import starImage1 from "../../assets/images/top-left.png"
import starImage2 from "../../assets/images/top-right.png"


export const Gamer = () => {
  return (
    <MainLayout className="Gamer-page">
      <div className="Gamer-container">
        <div className="congratulations-card">
        <div className="congrats-wrapper">
            <img
              className="small-image top-left"
              src={starImage1}
              alt="Top Left Decoration"
            />
            <p className="congrats-text">Congratulations!</p>
            <img
              className="small-image top-right"
              src={starImage2}
              alt="Top Right Decoration"
            />
          </div>
          <p className="unlock-text1">You have unlocked</p>
          <p className="unlock-text2">Gamer</p>

          <div className="avatar-card">
            <img
              src={gamercenter}
              alt="Fashionista Avatar"
            />
          </div>

          <p className="voucher-info">
            You will receive your
            <img
              className="brand-logo"
              src={reliancelogo}
              alt="Myntra Logo"
            />
            voucher, if you are<br/>one of the lucky winners!
          </p>
          <p className="stay-tuned">STAY TUNED...</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default Gamer;
