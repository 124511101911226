export const SET_USER_ID = "SET_USER_ID";
export const CLEAR_ACCESS_DETAILS = "CLEAR_ACCESS_DETAILS";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const CAN_CLAIM = "CAN_CLAIM";
export const ALREADY_DESIGNED = "ALREADY_DESIGNED";
export const LIMIT = "LIMIT";
export const COD = "COD";
export const SET_IP_DETAILS = "SET_IP_DETAILS";
export const CLEAR_IP_DETAILS = "CLEAR_IP_DETAILS";