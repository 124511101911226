import { Dispatch } from "redux";
import {
  CLEAR_ACCESS_DETAILS,
  SET_ACCESS_TOKEN,
  SET_USER_ID,
  CAN_CLAIM,
  LIMIT,
  COD,
  ALREADY_DESIGNED,
} from "../types";

export const setUserIdentification: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_ID,
      payload,
    });
  };

export const setAccessToken: any =
  (payload = "") =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload,
    });
  };

export const clearAccessDetails = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_ACCESS_DETAILS,
  });
};

export const canClaim: any =
  (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: CAN_CLAIM,
      payload: {
        canClaim: payload,
      },
    });
  };

  export const limit: any =
  (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: LIMIT,
      payload: {
        limit: payload,
      },
    });
  };
  export const uniqueCode: any =
  (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: COD,
      payload: {
        uniqueCode: payload,
      },
    });
  };
export const alreadyDesigned: any =
  (alreadyDesigned: boolean) => async (dispatch: Dispatch) => {
    // console.log(alreadyDesigned, "abcd");
    dispatch({
      type: ALREADY_DESIGNED,
      payload: {
        alreadyDesigned: { alreadyDesigned },
      },
    });
  };
