import {useTranslation} from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {

    return (
        <Popup className="howtoredeem-us-popup" title="HOW TO REDEEM">
    <div style={{textAlign:"left"}}>
        
    {/* <p dir="ltr">
    1. Enter the Unique code, Name, Email ID (optional), Mobile Number and click
    submit to receive OTP.
</p>
<p dir="ltr">
    2. Enter OTP and choose the preferred cashback option. Fill in the required
    details and click submit.
</p>
<p dir="ltr">
    3. The cashback will be processed on the chosen cashback option within 24
    business hours.
</p> */}
    </div>


        </Popup>
    );
};

export default HowTORedeemPopup ;