export enum LANGUAGE {
  ENGLISH = "en",
  // GUJARATHI = "gu",
  HINDI = "hi",
  // MARATHI = "mr",
  TAMIL = "ta",
  TELUGU = "te",
  // BENGALI = "bn",
  KANNADA = "kn",
  MALAYALAM = "ml",
}

// List used for drop down
export const LANGUAGES: Record<LANGUAGE, string> = {
  [LANGUAGE.ENGLISH]: "ENGLISH",
  // [LANGUAGE.GUJARATHI]: "ગુજરાતી",
  [LANGUAGE.HINDI]: "हिन्दी",
  // [LANGUAGE.MARATHI]: "मराठी",
  [LANGUAGE.KANNADA]: "ಕನ್ನಡ",
  [LANGUAGE.TELUGU]: "తెలుగు",
  [LANGUAGE.TAMIL]: "தமிழ்",
  // [LANGUAGE.BENGALI]: "বাংলা",
  [LANGUAGE.MALAYALAM]: "বাংলা",
};

export const ROUTES = {
  HOME: "/",
  REGISTER: "/register",
  MCQ: "/mcq",
  // REDEEM: "/redeem",
  REWARD: "/reward",
  CONGRATULATION: "/congrats",
  PARTICPATED: "participated",
  UPLOAD_SELFIE: "/upload",
  PAYOUT_FORM: "/payout-form",
  PAYMENT_PAGE: "/payment-page",
  PHONEPAY: "/phonepay",
  UPI_PAGE: "/upi-page",
  DESIGN_THANKYOU: "/design/thankyou",
  DESIGN_PARTICIPATED: "/design/participated",
  UPI_REWARD: "/upi-reward",
  DETAILS:"/details",
  GAMER:"/gamer",
  MUSIC: "/music",
  SPORTS:"/sport",
  EXPLORER:"/explorer",
  EXPLORERGIRL:"/explorergirl",

  

  DESIGN: "/design",
  DESIGN_COLOR: "/design/color",
  DESIGN_TEXT: "/design/text",
  DOWNLOAD: "/download",

  COMPLETED: "/completed-page",
  // SOCIAL_PROFILE: (profileId: string) => "/profile/" + profileId,
  // GAME_CITY: (city: string) => "/city/" + city,
  // USER_PROFILE: (action: "edit" | "view") => "/user-profile/" + action,
};

export const STATES = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];


export const OUTLET = [
  "Cessana",
  "Super Tail",
];
